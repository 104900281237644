<template>
  <div>
    <title>SIM INVENTORY ~ DATA LIST CASHBANK IN</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Add Data Cashbank In
        <br />
        <h4>Please enter data cashbank in</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save Cashbank In (Header)</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Number of Evidence</label>
                  <input
                    type="text"
                    @change="showgridcashbankin()"
                    v-model="isidata.nomorbukti"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Number of Evidence"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Date of Evidence</label>
                  <input
                    type="date"
                    v-model="isidata.tglbukti"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.keterangan"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Supplier</label>
                  <select2
                    :data="cdsup"
                    :value="valuesup"
                    @update="updatesup($event)"
                  ></select2>
                  <!--<select v-model="isidata.supkode" class="form-control">
                    <option
                      v-for="datasupkode in cdsup"
                      :key="datasupkode.id"
                      :value="datasupkode.kode_supplier"
                    >
                      {{ datasupkode.nama_supplier }}
                    </option>
                  </select> -->
                </div>
              </div>
              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Simpan
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save Cashbank In (Details)</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">COA Code</label>
                  <select2
                    :data="coalist"
                    :value="valuecoa"
                    @update="updatecoa($event)"
                  ></select2>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.catatdet"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Referensi</label>
                  <input
                    type="text"
                    v-model="isidata.refen"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Referensi"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Debet</label>
                  <input
                    type="text"
                    v-model="isidata.deb"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Debet"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Credit</label>
                  <input
                    type="text"
                    v-model="isidata.cre"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Credit"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="col-md-12">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">Data Lists Cashbank In</h3>
              </div>
              <!-- <div class="box-body">
                <div class="row">
                  <div class="col-xs-5">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-xs-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by Number of evidence"
                      class="form-control"
                      v-model="namesearch"
                    />
                  </div>
                  <div class="col-xs-2">
                    <button
                      @click="searchdata()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div> -->
            </div>

            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Number of evidence</th>
                      <th>COA Code</th>
                      <th>Reference</th>
                      <th>Debet</th>
                      <th>Credit</th>
                      <th>Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.ID"
                    >
                      <td>
                        {{ index + 1 + (page - 1) * pageSize }}
                      </td>
                      <!-- <td>{{ classdata.id }}</td> -->
                      <td>{{ classdata.no_bukti }}</td>
                      <td>{{ classdata.coa_code }}</td>
                      <td>{{ classdata.referensi }}</td>
                      <td>Rp. {{ formatPrice(classdata.debet) }}</td>
                      <td>Rp. {{ formatPrice(classdata.kredit) }}</td>
                      <td>{{ classdata.notes }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td><b style="color:red"> Rp. {{formatPrice(totalDebet(list_paged))}} </b></td>
                      <td><b style="color:teal"> Rp. {{formatPrice(totalKredit(list_paged))}} </b></td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
                <!-- <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                /> -->
              </div>
            </div>

            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Number of evidence</th>
                      <th>COA Code</th>
                      <th>Reference</th>
                      <th>Debet</th>
                      <th>Credit</th>
                      <th>Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="7" class="text-center">
                          <b style="color: red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
// import VPagination from "@hennge/vue3-pagination";
// import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "vggg",
 /*  components: {
    VPagination,
  }, */
  data() {
    return {
      isidata: {
        nomorbukti: "",
        tglbukti: "",
        keterangan: "",
        kodecoa: "",
        catatdet: "",
        refen: "",
        deb: "0",
        cre: "0",
        supkode: "",
        idxkasbankmasuk: "",
      },
      addData: true,
      isFrmeditData: false,
      isShow: false,
      isExist: true,
      isExist2: true,
      loading: false,
      namesearch: "",
      valuesup: "",
      valuecoa: "",
      list_paged: [],
      coalist: [],
      cdsup: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSizes: [10, 50, 100, 500],
      count2: 1,
      pageSize2: 10,
      pageSizes2: [10, 50, 100, 500],
      classdata: {
        kode_supplier: "",
      },
    };
  },
  created() {
    this.fetchData();
    this.listcoa();
    this.getnow()
    this.listsupplier();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchData();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.fetchData();
    },

    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    showgridcashbankin() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/jurnal_umum_h/getalldatajurnal_umum_h?length="+this.pageSize+"&page="+this.page
      const urlAPIget =
        this.$apiurl +
        "kas_bank_masuk_d/getalldatakas_bank_masuk_d?length=" +
        this.pageSize +
        "&page=" +
        this.page +
        "&no_bukti=" + this.isidata.nomorbukti
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          if (resp.data.data.length == 0) {
            this.isExist = false;
            this.loading = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    totalDebet(values) {
      return values.reduce((acc, val) => {
        return acc + parseInt(val.debet);
      }, 0);
    },
    totalKredit(values) {
      return values.reduce((acc, val) => {
        return acc + parseInt(val.kredit);
      }, 0);
    },
    getnow() {
      const today = new Date();
      const tahun = today.getFullYear();
      var month = today.getMonth();
      if (String(month).length == 1) {
        month = "0" + (month + 1);
      } else {
        month;
      }
      var tgl = today.getDate();
      if (String(tgl).length == 1) {
        tgl = "0" + tgl;
      } else {
        tgl;
      }
      // const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      this.isidata.tglbukti = tahun + "-" + month + "-" + tgl;
    },
    updatecoa(value){
      this.valuecoa = value;
    },
    listcoa() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getalldatabarang?length="+this.pageSize+"&page="+this.page;
      const urlAPIget =
        this.$apiurl +
        "kas_bank_masuk_d/getalldatacoa_cash_bank?page=1&length=5000";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          console.log(resp)
          this.coalist = resp.data.data;
          this.coalist.forEach((item) => {
            item.value = item.coa_code;
            item.label = item.coa_name;
          });
        })
        .catch((err) => {
          console.log(err.reponse.data);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    updatesup(value){
      this.valuesup = value;
    },
    listsupplier() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getalldatabarang?length="+this.pageSize+"&page="+this.page;
      const urlAPIget =
        this.$apiurl + "supplier/getalldatasupplier?length=12500&page=1";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          this.cdsup = resp.data.data;
          this.cdsup.forEach((item) => {
            item.value = item.kode_supplier;
            item.label = item.nama_supplier;
          });
        })
        .catch((err) => {
          console.log(err.reponse.data);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    async saveData() {
      this.loading = true;
      var nm = this.isidata.nomorbukti;
      var tgl = this.isidata.tglbukti;
      var ket = this.isidata.keterangan;
      var kdcoa = this.valuecoa;
      var cat = this.isidata.catatdet;
      var refe = this.isidata.refen;
      var cd = this.isidata.cre;
      var db = this.isidata.deb;
      var spkode = this.valuesup;
      if (nm == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Number of Evidence can not be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else if (tgl == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Date of Evidence can not be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else if (ket == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else {
        var kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          no_bukti: nm,
          tanggal_bukti: tgl,
          keterangan: ket,
          kode_user: kodeuser,
          kode_supplier: spkode,
          jenis: "CASHBANK",
        };

        const paramdatad = {
          no_bukti: nm,
          coa_code: kdcoa,
          notes: cat,
          kode_user: kodeuser,
          referensi: refe,
          debet: db,
          kredit: cd,
        };

        const tokenlogin = sessionStorage.getItem("token");
        const urlAPISaveData =
          this.$apiurl + "kas_bank_masuk_h/savekas_bank_masuk_h";
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin,
        };
        axios
          .post(urlAPISaveData, paramdata, { headers })
          .then(() => {
            const urlAPISaveDatad =
              this.$apiurl + "kas_bank_masuk_d/savekas_bank_masuk_d";

            axios
              .post(urlAPISaveDatad, paramdatad, { headers })
              .then((respn) => {
                if (respn.data.status == true) {
                  swal({
                    icon: "success",
                    title: "Success",
                    text: "Save data successfully",
                    showConfirmButton: false,
                  });
                  // this.isidata.nomorbukti = "";
                  // this.isidata.tglbukti = "";
                  // this.isidata.keterangan = "";
                  // this.valuesup = "";

                  this.valuecoa = "";
                  this.isidata.catatdet = "";
                  this.isidata.refen = "";
                  this.isidata.deb = "0";
                  this.isidata.cre = "0";
                  
                  this.loading = false;
                  this.fetchData();
                } else {
                  swal({
                    icon: "warning",
                    title: "Warning",
                    text: "Save data failed",
                    showConfirmButton: false,
                  });
                  this.loading = false;
                }
              })
              .catch((err) => {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: err.response.data,
                  showConfirmButton: false,
                });
                console.log(err);
                this.loading = false;
              });
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false,
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    searchdata() {
      this.loading = true;
      // const params = this.getRequestParams(this.page);
      const params = this.getRequestParams(this.pagestat);
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/jurnal_umum_h/getalldatajurnal_umum_h?cari=" + this.namesearch+"&length="+this.pageSize
      const urlAPIget =
        this.$apiurl +
        "kas_bank_masuk_d/getalldatakas_bank_masuk_d?cari=" +
        this.namesearch +
        "&length=" +
        this.pageSize +
        "&no_bukti=" + this.isidata.nomorbukti
      axios
        .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
        .then((resp) => {
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    async fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/jurnal_umum_h/getalldatajurnal_umum_h?length="+this.pageSize+"&page="+this.page
      const urlAPIget =
        this.$apiurl +
        "kas_bank_masuk_d/getalldatakas_bank_masuk_d?length=" +
        this.pageSize +
        "&page=" +
        this.page +
        "&no_bukti=" + this.isidata.nomorbukti
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          if (resp.data.data.length == 0) {
            this.isExist = false;
            this.loading = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          // const urlAPIbrgdel = "http://26.183.23.191/inventory/backend/inventory/api/supplier/hapussupplier/" + id;
          const urlAPIbrgdel =
            this.$apiurl + "kas_bank_masuk_d/hapuskas_bank_masuk_d/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin,
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully",
                })
                this.fetchData()
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed",
                }).then(function () {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data,
              });
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
